<template>
  <div class="mt-4x"></div>
  <GChart
    :settings="{
      packages: ['orgchart'],
      callback: () => {
        this.drawChart();
      },
    }"
    type="OrgChart"
    :data="chartData"
  />
</template>

<script>
import { GChart } from "vue-google-charts";

export default {
  components: {
    GChart,
  },
  data() {
    return {
      chartData: null,
    };
  },
  methods: {
    drawChart() {
      this.chartData = new google.visualization.DataTable();
      this.chartData.addColumn("string", "Name");
      this.chartData.addColumn("string", "Manager");
      this.chartData.addColumn("string", "ToolTip");

      this.$store.dispatch("getData", ["chart", []]).then((res) => {
        this.chartData.addRows(res);
      });
      var chart = new google.visualization.OrgChart(
        document.getElementById("tree")
      );
      chart.draw(this.chartData, { allowHtml: true, nodeClass: "custom-node" });
    },
  },
  mounted() {
    google.charts.load("current", { packages: ["orgchart"] }); // Load Google Charts API
    google.charts.setOnLoadCallback(() => {
      this.drawChart();
    });
  },
};
</script>

<style>
table {
  border-collapse: inherit;
  border-spacing: 0;
}

.google-visualization-orgchart-node {
  width: 200px !important;
}
</style>
