<template>
  <section id="cta" class="cta">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 text-center text-lg-start">
          <h3>Selamat Datang</h3>
        </div>
      </div>
    </div>
  </section>
  <section id="services" class="services section-bg">
    <div class="container">
      <div class="row">
        <div
          class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4"
          data-aos-delay="100"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-desktop"></i></div>
            <h4><a href="https://polindra.ac.id">INFORMASI POLINDRA</a></h4>
            <p>Informasi Mengenai Politeknik Negeri Indramayu</p>
          </div>
        </div>
        <div
          class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4"
          data-aos-delay="200"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bxs-window-alt"></i></div>
            <h4>
              <a href="https://elearning.polindra.ac.id/"
                >E-LEARNING POLINDRA</a
              >
            </h4>
            <p>
              Pembelajaran Elektronik Mahasiswa Dan Dosen Politeknik Negeri
              Indramayu
            </p>
          </div>
        </div>
        <div
          class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4"
          data-aos-delay="300"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-briefcase-alt"></i></div>
            <h4>
              <a href="https://siakad.polindra.ac.id/">SIAKAD POLINDRA</a>
            </h4>
            <p>
              Portal Yang Ditujukan Informasi Akademik Untuk Mahasiswa
              Politeknik Negeri Indramayu.
            </p>
          </div>
        </div>
        <div
          class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4"
          data-aos-delay="400"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-group"></i></div>
            <h4>
              <a href="https://pmb.polindra.ac.id/"
                >PENERIMAAN MAHASISWA BARU</a
              >
            </h4>
            <p>
              Informasi Mengenai Penerimaan Mahasiswa Baru Politeknik Negeri
              indramayu
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script></script>
