<template>
  <div id="faq" class="faq section-bg">
    <div class="container">
      <div class="section-title">
        <a href="/dokumen-senat">
          <h2>Dokumen Senat</h2>
        </a>
      </div>
      <div class="section-title search-form">
        <input
          class="form-control"
          type="search"
          aria-label="Search"
          v-model="searchQuery"
          @input="performSearch"
          placeholder="Cari dokumen..."
        />
        <button class="btn btn-outline-success ml-2" type="submit">
          <i class="bx bx-search-alt"></i>
        </button>
      </div>
      <div class="container">
        <div v-if="!data || data.length === 0" class="text-center">
          <p>Dokumen senat belum ditambahkan</p>
        </div>
        <div
          class="col-lg-4 col-md-6 portfolio-item ml-3"
          v-for="(dok, index) in data"
          :key="index"
        >
          <div class="row mb-3">
            <a :href="dok.linkUrl" target="_blank"> {{ dok.judulDokumen }}</a>
            <p>{{ dok.keterangan }}</p>
          </div>
          <div class="flipbook-container">
            <button
              @click="showContent = !showContent"
              class="btn btn-info btn-sm"
            >
              QR Code
            </button>
            <center v-if="showContent">
              <p>
                Anda juga dapat melihat langsung dengan scan QR Code Berikut
              </p>
              <img
                :src="dok.dokumenPath"
                alt="dokumen-senat"
                style="width: 200px"
              />
            </center>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mounted } from "vue";

export default {
  data() {
    return {
      showContent: false,
      data: {},
      judul_dokumen: "",
      tanggal_unggah: "",
      keterangan: "",
      link_url: "",
      dokumen_url: "",
      dokumen_name: "",
      dokumen_path: "",
      page: 1,
      totalPage: 10,
      searchQuery: "",
    };
  },

  methods: {
    getDataDokumen() {
      const self = this;
      self.$store
        .dispatch("getData", ["dokumen-senat", []])
        .then((response) => {
          this.data = response.data;
        });
    },
    performSearch() {
      const searchLower = this.searchQuery.toLowerCase();
      if (searchLower === "") {
        this.getDataDokumen();
      } else {
        this.data = this.data.filter(
          (dok) =>
            dok.judulDokumen.toLowerCase().includes(searchLower) ||
            dok.keterangan.toLowerCase().includes(searchLower)
        );
      }
    },
  },
  mounted() {
    this.getDataDokumen();
  },
};
</script>

<style>
.search-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  margin-left: auto;
}
</style>
