<template>
  <footer id="footer">
    <div class="footer-newsletter"></div>

    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 footer-contact">
            <h4>Politeknik Negeri Indramayu</h4>
            <p>
              Jl. Raya Lohberi Lama No. 08 <br />
              Indramayu 45252, Jawa Barat<br />
              Indonesia<br /><br />
              <strong>Telepon:</strong>
              (0234) 5746464<br />
              <strong>Email:</strong> info@polindra.ac.id<br />
            </p>
            <div class="social-links mt-3">
              <a
                href="https://www.instagram.com/politekniknegeriindramayu/"
                class="instagram"
                target="_blank"
                ><i class="bx bxl-instagram"></i
              ></a>
              <a
                href="https://www.youtube.com/@politekniknegeriindramayu4871"
                class="youtube"
                target="_blank"
                ><i class="bx bxl-youtube"></i
              ></a>
              <a
                href="https://web.facebook.com/Politeknik.Negeri.Indramayu/?locale=id_ID&_rdc=1&_rdr"
                class="facebook"
                target="_blank"
                ><i class="bx bxl-facebook"></i
              ></a>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Pusat Aplikasi</h4>
            <ul>
              <li>
                <i class="bx bx-link"></i>
                <a href="https://polindra.ac.id/">POLINDRA</a>
              </li>
              <li>
                <i class="bx bx-link"></i>
                <a href="https://elearning.polindra.ac.id/">E-learning</a>
              </li>
              <li>
                <i class="bx bx-link"></i>
                <a href="https://siakad.polindra.ac.id/">SIAKAD</a>
              </li>
              <li>
                <i class="bx bx-link"></i>
                <a href="/">Senat Polindra</a>
              </li>
              <li>
                <i class="bx bx-link"></i>
                <a href="https://pmb.polindra.ac.id/">PMB POLINDRA</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Jurusan</h4>
            <ul>
              <li><i class="bx bx-layer"></i> <a href="#">Teknik Mesin</a></li>
              <li>
                <i class="bx bx-layer"></i>
                <a href="https://ti.polindra.ac.id/">Teknik Informatika</a>
              </li>
              <li>
                <i class="bx bx-layer"></i>
                <a href="#">Teknik Pendingin dan Tata Udara</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Unit Kegiatan Mahasiswa</h4>
            <ul>
              <li><i class="bx bx-layer"></i> FOLAFO</li>
              <li><i class="bx bx-layer"></i> IMMAS</li>
              <li><i class="bx bx-layer"></i> KOMPA</li>
              <li><i class="bx bx-layer"></i> KOTAK PENA</li>
              <li><i class="bx bx-layer"></i> POPI</li>
              <li><i class="bx bx-layer"></i> PRAMUKA</li>
              <li><i class="bx bx-layer"></i> RPI</li>
              <li><i class="bx bx-layer"></i> SEBURA</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container footer-bottom clearfix">
      <div class="copyright">
        &copy; Copyright
        <strong><span>2023 - Designed And Developed By Polindra</span></strong
        >.
      </div>
    </div>
  </footer>
</template>
