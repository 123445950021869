<template>
  <section id="hero" class="d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
          data-aos="fade-up"
          data-aos-delay="200"
        ></div>
        <div
          class="col-lg-6 order-2 order-lg-3 hero-img"
          data-aos="zoom-in"
          data-aos-delay="200"
        ></div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style></style>
