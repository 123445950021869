<template>
  <div id="faq" class="faq section-bg">
    <div class="container">
      <div class="section-title">
        <a href="/galeri">
          <h2>Galeri</h2>
        </a>
      </div>
    </div>
  </div>
  <section id="portfolio" class="portfolio">
    <div class="container">
      <div v-if="!data || data.length === 0" class="col-lg-12 text-center mt-4">
        <p>Galeri belum ditambahkan</p>
      </div>
      <div class="row portfolio-container" data-aos-delay="200">
        <div
          class="col-lg-4 col-md-6 portfolio-item ml-3"
          v-for="(galeri, index) in data"
          :key="index"
        >
          <div class="portfolio-img">
            <img :src="galeri.fotoPath" class="img-fluid" alt="foto" />
          </div>
          <div class="portfolio-info align-item-stretch">
            <h4>{{ galeri.keterangan }}</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mounted } from "vue";

export default {
  data() {
    return {
      data: {},
      foto_path: "",
      keterangan: "",
      tanggal_unggah: "",
      page: 1,
      totalPage: 10,
    };
  },

  methods: {
    getDataGaleri() {
      const self = this;
      self.$store.dispatch("getData", ["gallery", []]).then((response) => {
        this.data = response.data;
      });
    },

    onClickPrev() {
      if (this.page > 1) {
        this.page--;
      }
    },
    onClickNext() {
      if (this.page < this.totalPage) {
        this.page++;
      }
    },
  },
  mounted() {
    this.getDataGaleri();
  },
};
</script>
<style>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.page-info {
  margin: 0 10px;
}
</style>
