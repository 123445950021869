<template>
  <div id="faq" class="faq section-bg">
    <div class="container">
      <div class="section-title">
        <a href="/sejarah-senat">
          <h2>Sejarah Senat Polindra</h2>
        </a>
      </div>
      <div class="container">
        <section id="skills" class="skills">
          <div class="container aos-init aos-animate" data-aos="fade-up">
            <div
              class="col-lg-6 d-flex align-items-center aos-init aos-animate"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <img
                v-if="data && !isEmptyObject(data) && data.fotoUrl"
                :src="data.fotoUrl"
                class="img-fluid"
                alt=""
              />
            </div>
            <div class="row">
              <div
                class="col-lg-12 mt-4 content aos-init aos-animate"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <p
                  class="justify-content-center"
                  v-if="data && !isEmptyObject(data) && data.isi"
                >
                  {{ data.isi }}
                </p>
                <p class="justify-content-center" v-else>
                  Sejarah senat belum ditambahkan
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      Judul: "",
      Isi: "",
    };
  },
  methods: {
    getDataSejarahSenat() {
      const self = this;
      self.$store
        .dispatch("getData", ["sejarah-senat", []])
        .then((response) => {
          this.data = response.data[0];
        });
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },
  },
  mounted() {
    this.getDataSejarahSenat();
  },
};
</script>
<style></style>
