<template>
  <div class="container">
    <div class="section-title">
      <a href="/keanggotaan">
        <h2>Keanggotaan</h2>
      </a>
    </div>
  </div>
  <div>
    <OrgChart />
  </div>
  <div v-if="!data || data.length === 0" class="col-lg-12 text-center mt-4">
    <p>Keanggotaan belum ditambahkan</p>
  </div>
</template>

<script>
import OrgChart from "@/components/OrgChart.vue";
export default {
  components: {
    OrgChart,
  },
  data() {
    return {};
  },
};
</script>
