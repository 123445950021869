<template>
  <div id="faq" class="faq section-bg">
    <div class="container">
      <div class="section-title">
        <a href="/sejarah">
          <h2>Sejarah Polindra</h2>
        </a>
      </div>
      <div class="container">
        <section id="skills" class="skills">
          <div class="container aos-init aos-animate" data-aos="fade-up">
            <div
              class="col-lg-6 d-flex align-items-center aos-init aos-animate"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <img v-if="data" :src="data.fotoUrl" class="img-fluid" alt="" />
            </div>
            <div class="row">
              <div
                class="col-lg-12 mt-4 content aos-init aos-animate"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <p class="justify-content-center" v-if="data">
                  {{ data.isi }}
                </p>
                <p class="justify-content-center" v-else>
                  Sejarah Polindra belum ditambahkan
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      Judul: "",
      Isi: "",
    };
  },
  methods: {
    getDataSejarah() {
      console.log("getDataSerjara");
      const self = this;
      self.$store.dispatch("getData", ["sejarah", []]).then((response) => {
        console.log(response.data);
        // self.dataSejarah = response.data;
        // const filters = this.filteredByIdSejarah();
        this.data = response.data[0];
      });
    },
  },
  mounted() {
    this.getDataSejarah();
  },
};
</script>
<style></style>
